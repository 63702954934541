import { useState } from "react";
import { useEventId } from "./useEvent";

const safeDefault: object = {
  pin: undefined,
  uid: undefined,
  orderUuid: undefined,
  orderId: undefined,
};

export interface AppData {
  team: Team;
  uid?: string;
  pin?: string;
  orderUuid: string;
  orderId: string;
  isAdmin?: boolean;
  isUploader?: boolean;
  hideUpgradePopup?: boolean;
  redirectBackUrl?: string;
  persistedScrollPosition?: number;
  locale?: AppLocale;
  consent?: boolean;
}

export const BASE_STORAGE_PATH = "camdeed-app-v1.1";

export const usePersistedState = (): [AppData, (AppData: AppData, eventId?: string) => void] => {
  const initialEventId = useEventId();
  const getStorageKey = (eventId?) => `${BASE_STORAGE_PATH}-${eventId ?? initialEventId}`;

  const isBrowser = typeof window !== "undefined";

  // Function to check if localStorage is available
  const isLocalStorageAvailable = () => {
    try {
      const testKey = "__test__";
      window.localStorage.setItem(testKey, testKey);
      window.localStorage.removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  };

  const canUseLocalStorage = isBrowser && isLocalStorageAvailable();

  const [storedValue, setStoredValue] = useState(() => {
    if (canUseLocalStorage) {
      try {
        const item = window.localStorage.getItem(getStorageKey());
        return item ? JSON.parse(item) : safeDefault;
      } catch (error) {
        console.error("Error reading localStorage:", error);
        return safeDefault;
      }
    } else if (isBrowser && typeof window.sessionStorage !== "undefined") {
      try {
        const item = window.sessionStorage.getItem(getStorageKey());
        return item ? JSON.parse(item) : safeDefault;
      } catch (error) {
        console.error("Error reading sessionStorage:", error);
        return safeDefault;
      }
    } else {
      console.error("Neither localStorage nor sessionStorage is available.");
    }
  });

  const setValue = (value, eventId = undefined) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);

      if (canUseLocalStorage) {
        window.localStorage.setItem(getStorageKey(eventId), JSON.stringify(valueToStore));
      } else if (isBrowser && typeof window.sessionStorage !== "undefined") {
        window.sessionStorage.setItem(getStorageKey(eventId), JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error("Error setting storage:", error);
    }
  };

  return [storedValue, setValue];
};
